<template>
	<Page color="neutralLight" breadcrumbs no-sidebar show-combos>
		<v-container class="py-md-0 pt-md-6">
			<CrossSellModal
				v-if="isHardCrossSell"
				:visible.sync="crossSellModal.visible"
				:products="_crossSellProducts"
				:events="eventsForCrossSells"
				:fullscreen="$vuetify.breakpoint.smAndDown"
				scrollable
				max-width="1000"
				@continue="onCrossSellContinue"
			/>

			<!-- PRODUCTS -->
			<div class="px-md-3">
				<v-skeleton-loader v-if="skeleton" type="heading" />
				<h2
					v-else
					class="display-1 font-weight-light"
					v-text="
						$options.filters.translatable(
							details.data.name,
							details.data.name_i18n
						)
					"
				></h2>
			</div>

			<v-row class="mt-8">

				<!-- LEFT -->
				<v-col cols="12" :md="5" class="mt-n6 pl-md-6">
					<Sticky class="py-6">
						<v-skeleton-loader v-if="skeleton" type="image" />
						<v-img
							v-else
							:aspect-ratio="16 / 9"
							:src="details.data.background"
						/>

						<div class="mt-4">
							<v-skeleton-loader
								v-if="skeleton"
								type="paragraph"
							/>
							<p class="body-1 font-weight-light" v-else>
								<span v-html="formattedDescription"></span>
									<a
										v-if="canShowAllDesc"
										class="secondaryDark--text"
										href
										@click.stop.prevent="showAllDesc = !showAllDesc"
										v-text="showAllDesc ? $t('product.readLess') : $t('product.readMore')"
									></a>
							</p>
						</div>
					</Sticky>
				</v-col>

				<!-- RIGHT -->
				<v-col cols="12" :md="rightColumnSpan" class="pr-md-6">
					<template v-if="skeleton">
						<v-card>
							<v-card-text>
								<v-skeleton-loader
									type="image"
								></v-skeleton-loader>
								<v-skeleton-loader
									type="heading mt-4"
								></v-skeleton-loader>
								<v-skeleton-loader
									class="mt-4"
									type="paragraph"
								></v-skeleton-loader>
							</v-card-text>
						</v-card>
					</template>
					<template v-else>
						<v-alert
							v-if="_tagProducts.results.length === 0"
							type="info"
							prominent
							text
						>
							<span v-text="$t('tag.noProducts')"></span>
						</v-alert>

						<template v-if="productsByEventGroups === 1">
							<Calendar
								v-if="showCalendar"
								v-model="selectedEventID"
								:event-group-ids="eventGroupIds"
								class="mb-4"
								@daySelect="daySelected"
							>
								<template v-slot:prepend-month>
									<span
										class="title"
										v-text="$t('calendar.dateOfVisit')"
									></span>
								</template>
								<template v-slot:prepend-day>
									<span
										class="title mt-3"
										v-text="$t('calendar.timeOfVisit')"
									></span>
								</template>
								<template v-slot:append-day>
									<span
										class="title mt-3"
										v-text="$t('calendar.chooseYourItems')"
									></span>
								</template>
							</Calendar>
							<template>
  								<div
									v-for="(product, index) in _tagProducts.results"
									:key="product.guid + '_' + index + '_tagProduct'"
								>
    								<ComboCard
      									v-if="product.data.is_combo && isTimeBasedCombos"
      									:combo="product"
      									:skeleton="skeleton"
      									:selectedDay.sync="selectedDay"
      									:selectedEventID.sync="selectedEventID"
      									:class="{'mt-8': index > 0}"
    								/>
									<Product
										v-else
										:product="product"
										:skeleton="skeleton"
										:day-selected.sync="selectedDay"
										:event-id.sync="selectedEventID"
										:class="{'mt-8': index > 0}"
									/>
  								</div>
							</template>
						</template>
						<template v-else>
							<div class="mb-4" v-for="([key, products], index) in productWithEventGroup" :key="key">
								<v-card class="pa-4" outlined>
									<EventGroupCalendarProducts
										:event-group-products="products"/>
								</v-card>
							</div>
							<div v-for="(product, index) in productNoEventGroup"
								:key="index"
								class="mb-4">
								<ComboCard
									v-if="product.data.is_combo && isTimeBasedCombos"
									:key="product.guid +'_' +index +'_tagProduct'"
									:combo="product"
									:skeleton="skeleton"
									:selectedDay.sync="selectedDay"
									@addDisableModifierData="addDisableModifierData"
									:disabledModifier="disabledModifier"
									@handleSlotClick="handleSlotClick"
									:selectedEventID.sync="selectedEventIdByEG[product.data.event_group.id?.toString()]"
								/>
								<v-card class="pa-4" outlined v-else>
									<Product
										:key="product.guid +'_' +index +'_tagProduct'"
										:class="{'mt-8': index > 0}"
										:product="product"
										product-type="tag"
										:skeleton="skeleton"
										:day-selected.sync="selectedDay"
										@addDisableModifierData="addDisableModifierData"
										:disabledModifier="disabledModifier"
										@handleSlotClick="handleSlotClick"
										:event-id.sync="selectedEventIdByEG[product.data.event_group.id?.toString()]"
									/>
								</v-card>
							</div>
						</template>
					</template>
				</v-col>
			</v-row>

			<div class="px-md-3 mb-8">
				<!-- CROSS-SELLS -->
				<div
					v-if="
						!isHardCrossSell &&
						_crossSellProducts.results.length > 0
					"
					class="mt-8"
				>
					<v-skeleton-loader v-if="skeleton" type="heading" />
					<h2
						v-else
						class="display-1 font-weight-light text-center"
						v-text="$t('tag.otherOptions')"
					></h2>

					<v-row class="mt-6">
						<v-col
							:key="
								product.guid + '_' + productIdx + '_crossSell'
							"
							v-for="(
								product, productIdx
							) in _crossSellProducts.results"
							style="min-width: 20rem"
						>
							<Product
								:product="product"
								:skeleton="skeleton"
								:is-cross-sell="true"
								:show-calendar="showCalendar"
								:day-selected.sync="selectedDay"
							/>
						</v-col>
					</v-row>
				</div>
			</div>

			<!-- PROCEED TO CHECKOUT -->
			<div :class="['sticky-container rounded py-5 px-5 mx-md-3 mx-0',$root.cart.data.itemCount ? 'active' : '']">
				<div class="button-container">
					<!-- TAGS -->
					<v-btn
						color="button"
						outlined
						x-large
						:to="{ name: 'home' }"
					>
						<v-icon left>mdi-arrow-left</v-icon>
						<span v-text="$t('btn.seeAllOptions')"></span>
					</v-btn>

					<v-btn
						data-testid="checkoutBtn"
						color="button"
						:disabled="$root.cart.data.itemCount === 0"
						x-large
						class="ml-5"
						@click="onProceedCheckout"
					>
						<v-icon left>mdi-cart</v-icon>
						<span v-text="$t('btn.proceedCheckout')"></span>
					</v-btn>
				</div>
			</div>
		</v-container>

		<InteractiveMapCrossSellModal
			v-if="hasInteractiveMap"
			:next-action="() => $router.push({ name: 'cart' })"
			:visible="showInteractiveMapModal"
			@modalClosed="showInteractiveMapModal = false" />

	</Page>
</template>

<script>
import Calendar from "@/components/Calendar";
import Product from "@/components/Product";
import Page from "@/components/Page";
import Sticky from "@/components/Sticky";
import ComboCard from "@/components/ComboCard";
import CrossSellModal from "@/components/CrossSellModal";
import {
	BaseModel,
	PaginationModel,
	ProductModel,
	EComService,
	EventBus,
} from "@connectngo/sdk";
import moment from 'moment'
import globalVariables from '@/global'
import InteractiveMapCrossSellModal from "@/components/InteractiveMap/InteractiveMapCrossSellModal";
import InteractiveMapMixin from "@/mixins/InteractiveMapMixin";
import EventGroupCalendarProducts from "@/components/EventGroupCalendarProducts";
import Text from "@/utils/Text";

export default {
	name: "Tag",

	components: {
		EventGroupCalendarProducts,
		InteractiveMapCrossSellModal,
		Calendar,
		Page,
		Product,
		Sticky,
		CrossSellModal,
		ComboCard
	},

	mixins: [InteractiveMapMixin],

	metaInfo() {
		return {
			title: this.title,
			meta: [
				{
					property: "description",
					content: this.$options.filters.translatable(
						this.details.data.description,
						this.details.data.description_i18n,
						this.$i18n.locale
					),
				},
				{
					property: "og:title",
					content: this.$options.filters.translatable(
						this.details.data.name,
						this.details.data.name_i18n,
						this.$i18n.locale
					),
				},
				{ property: "og:url", content: window.location.href },
				{
					property: "og:description",
					content: this.$options.filters.translatable(
						this.details.data.description,
						this.details.data.description_i18n,
						this.$i18n.locale
					),
				},
				{ property: "og:image", content: this.details.data.background },
				{
					property: "twitter:image:src",
					content: this.details.data.background,
				},
			],
		};
	},

	data: () => ({
		skeleton: true,
		loading: true,
		details: new BaseModel(),
		crossSellProducts: new PaginationModel(),
		tagProducts: new PaginationModel(),
		// allTags: new PaginationModel(),
		showCalendar: false,
		calendarSlots: [],
		currentMonth: new Date().getMonth(),
		currentYear: new Date().getFullYear(),
		eventGroupIds: [],
		unavailableSlots: [],
		selectedEventID: null,
		selectedDay: null,
		productsByEventGroups: {},
		productsByEventGroupsCustom: [],
		availableProductsByEventGroups: {},
		selectedEventIdByEG: [],
		title: "",
		eventsForCrossSells: {},
		crossSellModal: {
			visible: false,
		},
		showAllDesc: false,
		eventEventBus: null,
		showInteractiveMapModal : false,
		disabledModifier: []
	}),

	computed: {
		rightColumnSpan() {
			return this.allCombo && this.isTimeBasedCombos ? 6 : 7;
		},
		isTimeBasedCombos() {
			const { feature_flags } = this.$root.websiteConfig.data || {};
			return feature_flags.includes('co-5754-time-based-combos');
		},
		_tagProducts() {
			const product = new ProductModel();
			return this.skeleton
				? new PaginationModel([product, product, product])
				: this.tagProducts;
		},
		_crossSellProducts() {
			const product = new ProductModel();
			return this.skeleton
				? new PaginationModel([product, product, product])
				: this.crossSellProducts;
		},
		isHardCrossSell() {
			return this.$root.websiteConfig.data.fields.hard_cross_sell === "1";
		},
		productWithEventGroup() {
			const products = this.productsByEventGroupsCustom.filter(product => product.data.event_group.id);
			const groupedProductsByEventGroup = this.groupByMaintainingOrder(products, product => product.data.event_group.id);
			return Array.from(groupedProductsByEventGroup.entries());
		},
		productNoEventGroup() {
			return this.productsByEventGroupsCustom.filter(product => !product.data.event_group.id);
		},
		canShowAllDesc() {
			return this.description.length > 200 && this.windowWidth < 420;
		},
		windowWidth() {
			return window.innerWidth;
		},
		allCombo() {
			return this._tagProducts.results.every((product) => product.data.is_combo);
		},
		description() {
			return this.$options.filters.translatable(
				"",
				this.details.data.description_i18n,
				this.$i18n.locale
			) || ''
		},
		formattedDescription() {
			const trimmed = Text.trimNotWithinWords(
				this.description.replace(/<\/?[^>]+(>|$)/g, ""),
				200
			);
			return this.windowWidth < 420 && this.canShowAllDesc && !this.showAllDesc
				? trimmed.length === this.description.length
					? trimmed
					: trimmed + "..."
				: this.description;
		}
	},

	methods: {
		// Custom grouping function that respects the original order
		groupByMaintainingOrder(array, keyFunc) {
			const map = new Map();

			array.forEach(item => {
				const key = keyFunc(item);
				if (!map.has(key)) {
					map.set(key, []);
				}
				map.get(key).push(item);
			});

			return map;
		},
		addDisableModifierData(data) {
			this.disabledModifier.push(data);
			this.disabledModifier =  Array.from(new Set(this.disabledModifier.map(JSON.stringify))).map(JSON.parse);
		},
		handleSlotClick(slotMoment, eventGroup) {
			if(this.disabledModifier.length > 0) {
				this.disabledModifier.forEach((data,index) => {
					const disabledModifierUnavailableBefore = eventGroup.data.modifiers.find(modifier => modifier.data.id === data.slotId)?.data.unavailable_before;
					let disabledSlot = slotMoment
							.isAfter(
								moment.unix(disabledModifierUnavailableBefore)
							)
					if(disabledSlot) {
						this.disabledModifier[index]['available'] = true;
					} else {
						this.disabledModifier[index]['available'] = false;
					}
				});
			}
		},
		onCrossSellContinue() {
			this.$router.push({ name: "cart" });
		},

		onProceedCheckout() {
			if (
				this.isHardCrossSell && this._crossSellProducts.results.length > 0
			) {
				this.crossSellModal.visible = true;
			} else if(!this.isHardCrossSell && this.mustDisplayInteractiveMapCrossSellModal) {
				this.showInteractiveMapModal = true;
				globalVariables.interactiveMapModalShown = true;
			} else {
				this.$router.push({ name: "cart" });
			}
		},

		triggerGAEvent(tagDetails) {
			const items = [];
			tagDetails.data.products?.forEach((product, index) => {
				items.push({
					item_id: product.data.id,
					item_name: this.$options.filters.translatable(
						product.data.name,
						product.data.name_i18n,
						this.$i18n.locale
					),
					currency: this.$root.websiteConfig.data.tenant.currency
						.code,
					item_category: this.$options.filters.translatable(
						tagDetails.data.name,
						tagDetails.data.name_i18n,
						this.$i18n.locale
					),
					index: index++,
					price: product.data.price,
					quantity: 1,
				});
			});
			if(this.$gtm) {
				dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
				dataLayer.push({
					event: "view_item_list",
					tenant_id: this.$root.websiteConfig.data.tenant.id,
					ecommerce: {
						items: items,
					},
				});
			}
		},

		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getTagDetails(this.$route.params.key),
				new EComService().getCrossSell(this.$route.params.key),
			])
				.then(([tagDetails, crossSellProducts]) => {
          // Prevent navigation for reload tag
          if (tagDetails.data.reloadable) {
            return this.$router.push({name : 'reloadV2'})
          }

					this.title = this.$options.filters.translatable(
						tagDetails.data.name,
						tagDetails.data.name_i18n
					);
					this.$route.meta.name = this.title;
					this.$breadcrumbs.refresh();

					this.crossSellProducts = crossSellProducts;
					this.tagProducts = new PaginationModel(
						tagDetails.data.products,
						ProductModel
					);
					// this.allTags = tags;
					this.details = tagDetails;
					this.skeleton = false;

					tagDetails.data.products?.forEach((product) => {
						this.productsByEventGroupsCustom.push(product);

						if (product.data.event_group?.id === null) {
							if (!this.productsByEventGroups.no_event) {
								this.productsByEventGroups.no_event = [];
							}
							this.productsByEventGroups.no_event.push(product);
						} else {
							if (
								!this.productsByEventGroups[
									product.data.event_group.id
								]
							) {
								this.productsByEventGroups[
									product.data.event_group.id
								] = [];
							}
							this.productsByEventGroups[
								product.data.event_group.id
							].push(product);
							this.selectedEventIdByEG[
								product.data.event_group.id.toString()
							] = null;
						}
					});

					this.availableProductsByEventGroups = Object.assign({}, this.productsByEventGroups);

					const eventGroupIds = tagDetails.data.products
						.filter((product) => product.data.event_group?.id)
						.map((product) => product.data.event_group.id)
						.flat();
					// checking if any product from a same event group has a null unavailable_before
					const isUnavailableBefore = tagDetails.data.products.filter(
						product => product.data.unavailable_before === null
						&& product.data.event_group?.id != null);
					const unavailableSlot = tagDetails.data.products
					.filter((product) => product.data.unavailable_before)
					.map((product) => {
						const unavailableProd = {
							unavailable_before:  product.data.unavailable_before,
							eventGroupId: JSON.stringify(product.data.event_group?.id),
							isUnavailableBefore: isUnavailableBefore?.find(
								unProduct => unProduct.data.event_group?.id === product.data.event_group?.id)
						};
						return unavailableProd
						});
					this.unavailableSlots = [...new Set(unavailableSlot)];

					this.eventGroupIds = [...new Set(eventGroupIds)];
					if (this.eventGroupIds.length > 0) {
						this.showCalendar = true;
					}

					this.triggerGAEvent(tagDetails);
				})
				.catch((reason) => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
		daySelected(value) {
			this.selectedDay = value;
			this.availableProductsByEventGroups = Object.assign({}, this.productsByEventGroups);
		},
		handleEventSelected(eventInfo) {
			if (eventInfo.eventGroup === null) {
				this.selectedEventID = eventInfo.id;
			} else {
				this.selectedEventIdByEG[eventInfo.eventGroup] = eventInfo.id;

				if (this.availableProductsByEventGroups[eventInfo.eventGroup]) {
					//reset available products for group
					this.$delete(this.availableProductsByEventGroups, eventInfo.eventGroup)
				}

				this.productsByEventGroups[eventInfo.eventGroup]?.forEach((product) => {
					if (!product.data.unavailable_before) {
						product.data.is_available = true
					} else {
						product.data.is_available = eventInfo
							.slot
							.from
							.isAfter(
								moment.unix(product.data.unavailable_before)
							)
					}

					if (product.data.is_available) {
						if (!this.availableProductsByEventGroups[eventInfo.eventGroup]
						) {
							this.availableProductsByEventGroups[eventInfo.eventGroup] = []
						}

						this.availableProductsByEventGroups[eventInfo.eventGroup].push(product)
					}
				})
			}
		},
	},
	created() {
		this.title = this.$i18n.t("route.tags");
		this.$route.meta.name = this.$i18n.t("state.loading");
		this.load();
	},
	mounted() {
		this.eventEventBus = EventBus.subscribe(
			"event",
			this.handleEventSelected
		);
	},
	destroyed() {
		this.eventEventBus.unsubscribe();
	},
};
</script>

<style lang="scss" scoped>
.combo-container {
	h5 {
		//styleName: Paragraph;
		font-size: 25px;
		font-weight: 400;
		line-height: 39px;
		letter-spacing: 0px;
		text-align: left;
	}
	.intro {
		background-color: #fff;
		.combo-image {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
	}
	.intro ul {
		display: flex;
		flex-direction: column;
		padding-left: 16px;
		gap: 24px;
		//styleName: Paragraph;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		text-align: left;
		li {
  			list-style-type: none;
		}

		li:before {
  			content: "-";
  			padding-right: 8px;
		}
	}
}

.product-item:not(:first-child) {
	margin-top: 1.5rem;
}

.sticky-container {
	position: relative;
	background-color: #fff;
}

.sticky-container.active{
	position: sticky;
	position: -webkit-sticky;
	bottom: 0%;
	z-index: 2;
}

.sticky-container .button-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media all and (max-width: 620px) {
	.sticky-container .button-container button, .sticky-container .button-container a{
		font-size: 0.7rem;
		padding: 0;
	}
}

@media all and (max-width: 430px) {
	.sticky-container .button-container {
		display: grid;
	}

	.sticky-container .button-container button{
		margin-left: 0 !important;
		margin-top: 10px;
	}
}
</style>
