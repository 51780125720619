<template>
	<v-skeleton-loader v-if="skeleton" type="list-item,list-item,list-item" />
	<v-sheet v-else data-test-selector="adyen_form">

		<div v-if="_loading" class="d-flex justify-center">
			<v-progress-linear indeterminate />
		</div>

		<div data-testid="adyenForm" id="adyen_form_container" v-show="dropinElement"></div>

		<v-checkbox data-testid="agreeTnc" v-on:click="unlockCardPayments" v-model="readTerms" :disabled="paying || readTerms" name="read_terms" :rules="[rules.required]">
			<template v-slot:label>
				<span>
					<span v-text="$t('paymentForm.creditBalanceDialog.readTerms')"></span>
					&nbsp;<a @click.prevent.stop="handleTermsClick" v-text="$t('paymentForm.creditBalanceDialog.terms')"></a>
				</span>
			</template>
		</v-checkbox>

		<div class="g-recaptcha"
			v-if="enableCaptcha"
			data-callback="onReCaptchaSuccess"
			:data-sitekey="$root.websiteConfig.data.gcp_recaptcha"
			data-action="PAYMENT"></div>
	</v-sheet>
</template>

<script>
import { AdyenCheckout, Dropin } from '@adyen/adyen-web/auto';
import '@adyen/adyen-web/styles/adyen.css';
import { PaymentService, EventBus, CartModel, BaseModel, Rules, EComService } from '@connectngo/sdk';
import CaptchaMixin from "@/mixins/CaptchaMixin";

export default {
	name: 'AdyenForm',

	mixins: [CaptchaMixin],

	props: {
		cart: {
			type: CartModel,
			default: () => new CartModel(),
		},
		engine: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		balance: {
			type: Number,
			default: null
		}
	},

	data: () => ({
		loading: false,
		paying: false,
		success: false,
		error: false,
		readTerms: false,
		dropinElement: null,
		dropInData: null,
		redirectUrl: null,
		paymentLockSeconds: 0,
		adyenButton: null,
		captchaReady: false
	}),

	computed: {
		_loading() {
			return this.loading && !this.dropinElement;
		},
		canPay() {
			const captchaCheck = this.enableCaptcha ? this.captchaReady : true;
			return !this.paying
				&& !this.loading
				&& this.readTerms
				&& captchaCheck;
		}
	},

	methods: {
		onCaptchaSuccess() {
			this.captchaReady = true;
			this.unlockCardPayments();
		},

		handleTermsClick() {
			EventBus.publish('OPEN_PAGE', 'terms');
		},

		async loadDropIn() {
			this.loading = true;
			const amount = this.balance || this.cart.data.total;
			try {
				const response = await new PaymentService().getAdyenDropIn(
					this.engine.data.id,
					amount
				);

				this.dropInData = response.data;

				const checkout = await AdyenCheckout({
					paymentMethodsResponse: response.data.payment_methods,
					clientKey: response.data.client_key,
					locale: response.data.locale,
					environment: response.data.environment,
					countryCode: response.data.countryCode,
					onSubmit: async (state, component, actions) => {
						this.pay(state, actions);
					},
					onError: (error) => {
						// Coming back from an error or a cancel
						this.setDropInState('ready');
						console.error("Adyen error:", error);
					}
				});

				const dropinConfiguration =  {
					openFirstPaymentMethod: false,
					showStoredPaymentMethods: false,
					paymentMethodsConfiguration: {
						card: {
							hasHolderName: true,
							holderNameRequired: true,
							placeholders: {
								holderName: 'J. Smith',
								cardNumber: '1234 5678 9012 3456',
							},
							enableStoreDetails: false,
							billingAddressRequired: false
						},
						applepay: {
							amount: {
								currency: response.data.currency,
								value: response.data.amount
							}
						},
						googlepay: {
							amount: {
								currency: response.data.currency,
								value: response.data.amount
							}
						},
					},
					onReady: () => {
						if (
							this.$route.query.redirectResult
							|| this.canPay
						) {
							// Coming back from an error or a cancel
							// Or if the terms and captcha are already checked while Adyen was loading the component
							this.setDropInState('ready');
						} else {
							// To block cards
							this.setDropInState('loading');
						}
					},
				};
				this.dropinElement = new Dropin(checkout, dropinConfiguration).mount('#adyen_form_container');
			} catch (error) {
				this.$handleError(this, error);
			} finally {
				this.loading = false;
			}
		},

		setDropInState(state) {
			this.dropinElement.setStatus(state);
		},

		unlockCardPayments: function () {
			if (this.canPay) {
				this.setDropInState('ready');
			}
		},

		pay(state, actions) {
			this.paying = true;
			this.error = false;
			this.$emit('paymentOverlay', [this.paying, this.success, this.error]);
			this.dropinElement.setStatus('loading');

			new PaymentService().processAdyenPayment(
				this.engine.data.id,
				this.cart.data.uuid,
				state.data,
				this.redirectUrl,
				this.cart.data.amountToPay,
				this.cart.deposit
			)
			.then(response => {
				this.handlePaymentSuccess(response);
				const resultCode = response.resultCode;
				actions.resolve({resultCode});
			})
			.catch(error => {
				this.handlePaymentError(error);
				actions.reject();
			})
		},

		handlePaymentSuccess(response) {
			if (response.data.action) {
				this.dropinElement.setStatus('ready');
				this.dropinElement.handleAction(response.data.action);
			} else {
				this.$emit('completed', response);
			}
			this.paying = false;
			this.$emit('paymentOverlay', [this.paying, this.success, this.error]);
		},

		handlePaymentError(error) {
			if (this.$isAlreadyPaid(error)) {
				this.paying = false
				this.$emit('paymentOverlay', [this.paying, this.success, this.error]);
				this.$emit('completed');
			}

			if (this.$isAlreadyLocked(error) && this.paymentLockSeconds < error.timeout) {
				this.checkPaymentLock();
			} else {
				this.loadCaptcha();
				this.paymentLockSeconds = 0;
				this.paying = false;
				this.error = true;
				this.dropinElement.setStatus('error', error);
				this.$emit('paymentOverlay', [this.paying, this.success, this.error]);
				setTimeout(() => {
					this.dropinElement.setStatus('ready');
				}, 3000);
			}
		},

		checkPaymentLock() {
			return new EComService().getPaymentLockStatus(this.cart.data.uuid)
				.then(response => {
					this.paymentLockSeconds = 0;
					this.handlePaymentSuccess(response);
				})
				.catch(error => {
					this.paymentLockSeconds += 2;
					setTimeout(() => {
						this.paying = true;
						this.$emit('paymentOverlay', [this.paying, this.success, this.error]);
						this.dropinElement.setStatus('loading');
						this.handlePaymentError(error);
					}, 2000)
				})
		},

		doCallback(paymentId, redirectResult) {
			new PaymentService().doAdyenCallback(paymentId, redirectResult)
				.then(response => {
					this.error = false;
					this.$emit('paymentOverlay', [this.paying, this.success, this.error]);
					this.$emit('completed', response);
				})
				.catch(error => {
					this.error = true;
					this.$emit('paymentOverlay', [this.paying, this.success, this.error]);
					this.dropinElement.setStatus('error', error);
					setTimeout(() => {
						this.dropinElement.setStatus('ready');
					}, 3000);
				});
		},
	},

	async created() {
		this.rules = {
			required: value => Rules.required(value) || this.$t('rules.required'),
			creditCardNumber: value => Rules.creditCardNumber(value) || this.$t('rules.creditCardNumber'),
			creditCardCvc: value => Rules.creditCardCvc(value) || this.$t('rules.creditCardCvc'),
			creditCardExp: value => Rules.creditCardExp(value) || this.$t('rules.creditCardExp'),
			zipPostal: value => Rules.zipCode(value) || Rules.postalCode(value) || this.$t('rules.creditCardZipPostal'),
		};

		const threeDsResolutionRoutes =  ['cart_payment_3ds', 'reservation_3ds'];
		if (threeDsResolutionRoutes.includes(this.$route.name)) {
			this.doCallback(this.$route.params.id, this.$route.query.redirectResult);
		}

		await this.loadDropIn();

		const reservationRoutes = ['reservation', 'reservation_3ds'];
		if (reservationRoutes.includes(this.$route.name)) {
			this.redirectUrl = window.location.origin + this.$router.resolve(
				{
					name: 'reservation_3ds_prepare',
					params: this.$route.params
				}
			).href;
		} else {
			this.redirectUrl = window.location.origin + this.$router.resolve({ name: 'cart_payment_3ds_prepare', params: { step: 'payment', engine: 'adyen_ecom' } }).href;
		}

		if (this.$route.query.redirectResult) {
			this.readTerms = true;
		}
	},

	mounted() {
		this.$on('captcha-success', this.onCaptchaSuccess);
	},
}
</script>
