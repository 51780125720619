<template>
	<div class="mt-5" id="paypal-button-container"></div>
</template>

<script>
	import { loadScript } from "@paypal/paypal-js";
	export default {
		name: 'paypal',
		props: {
			client_id: {
				type: String,
				required: true
			}
		},
		mounted() {
			this.loadPaypal();
		},
		methods: {
			async loadPaypal() {
			try {
				const paypal = await loadScript({
						clientId: this.client_id,
						currency: this.$root.websiteConfig.data.tenant.currency.code,
						disableFunding: 'card'
					});

					paypal.Buttons({

					}).render("#paypal-button-container");
				} catch (error) {
					console.error("Failed to load PayPal SDK:", error);
					this.$emit("payment-error", error);
				}
			},
		},
	}
</script>