// @ts-nocheck
const GoogleAnalyticsMixin = {
	methods: {
		triggerCartGAEvent() {
			if (this.$gtm && window.dataLayer) {
				window.dataLayer.push({ ecommerce: null })
				window.dataLayer.push({
					event: 'view_cart',
					tenant_id: this.$root.websiteConfig.data.tenant.id,
					ecommerce: {
						currency: this.$root.websiteConfig.data.tenant.currency.code,
						value: this.cart.data.total,
						items: this.buildGAItems(),
					},
				})
			}
		},
		triggerCheckoutGAEvent () {
			if (this.$gtm && window.dataLayer) {
				window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
				window.dataLayer.push({
					event: 'begin_checkout',
					tenant_id: this.$root.websiteConfig.data.tenant.id,
					ecommerce: {
						items: this.buildGAItems(),
					},
				})
			}
		},
		buildGAItems() {
			return this.cart.data.items.map((item, index) => ({
				item_id: item.data.product.data.id,
				item_name: this.$options.filters.translatable(
					item.data.product.data.name,
					item.data.product.data.name_i18n,
					this.$i18n.locale
				),
				coupon: item.data.hasDiscount
					? this.$options.filters.translatable(
						item.data.discount.name,
						item.data.discount.name_i18n,
						this.$i18n.locale
					)
					: null,
				currency: this.$root.websiteConfig.data.tenant.currency.code,
				discount: item.data.discountTotal,
				index,
				item_category: item.data.mainTag
					? this.$options.filters.translatable(
						item.data.mainTag.name,
						item.data.mainTag.name_i18n,
						this.$i18n.locale
					)
					: '',
				price: item.data.total,
				quantity: item.data.quantity,
			}))
		},
	},
}

export default GoogleAnalyticsMixin;
